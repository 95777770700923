// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
export const storageAvailable = type => {
  if (typeof window === 'undefined') {
    return;
  }
  var storage;
  try {
    storage = window[type];
    var x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch(e) {
    return e instanceof window.DOMException && (
      // everything except Firefox
      e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      (storage && storage.length !== 0);
  }
};
// check if user has ever logged in via the marketing Site
export const hasLoggedIn = () => {
  const okLocalStorage = storageAvailable('localStorage');
  if (!okLocalStorage) {
    return false;
  }
  if (
    typeof window !== 'undefined',
    window.localStorage.getItem('usesessioncam') === 'false' || // left over from SC original use
    window.localStorage.getItem('hasloggedin') === 'true'
  ) {
    return true;
  }
  return false;
};
export const loadDynamicScript = (scriptId, url, callback) => {
  if (!document) {
    return;
  }
  const existingScript = document.getElementById(scriptId);
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = url;
    script.id = scriptId;
    script.setAttribute('defer', ''); // added defer 011022
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) {
        callback(false); // bool = already loaded script
      }
    };
  }
  if (existingScript && callback) {
    callback(true); // bool = already loaded script
  }
};

export const loadDynamicCss = (cssId, url, callback) => {
  if (!document) {
    return;
  }
  const existingCss = document.getElementById(cssId);
  if (!existingCss) {
    const css = document.createElement('link');
    css.href = url;
    css.id = cssId;
    css.rel = 'stylesheet';
    css.type = 'text/css';
    document.getElementsByTagName('head')[0].appendChild(css); // when styles load, causes scroll to top of screen
    css.onload = () => {
      if (callback) {
        callback(false); // bool = already loaded css
      }
    };
  }
  if (existingCss && callback) {
    callback(true); // bool = already loaded script
  }
};


// Add the Microsoft Advertising UET tag tracking code to marketing site
const IS_DEV = process.env.GATSBY_ENVIRONMENT && process.env.GATSBY_ENVIRONMENT === 'dev';
const UET_SCRIPT = '(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"137001439"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");';

const MsAds = {
  didInit: false,
  pageview: function(location) {
    if ( 
      IS_DEV ||
      typeof window === 'undefined' || 
      !window.uetq || 
      ( this && !this.didInit )
    ) {
      return;
    }
    window.uetq.push('event', 'page_view', { 
      page_path: location.pathname
    });
  },  
  init: function() {
    if (
      IS_DEV ||
      typeof window === 'undefined' || 
      ( this && this.didInit )
    ) {
      return;
    }
    this.didInit = true;
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    // script.src = UET_SCRIPT;
    script.text = UET_SCRIPT;
    script.id = 'UET_SCRIPT';    
    script.removeAttribute('async');
    script.setAttribute('defer',''); // changed from async to defer 011022
    head.appendChild(script);
  }
};

export default MsAds;
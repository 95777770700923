
const IS_DEV = process.env.GATSBY_ENVIRONMENT && process.env.GATSBY_ENVIRONMENT === 'dev';

const Facebook = {
  didInit: false,
  fBPixelTrackPageView: function () {
    if (window.fbq) {
      window.fbq('track', 'PageView');
    }
  },
  fBPixelTrackDemoSchedule: function (accountString) {
    if (window.fbq) {
      window.fbq('track', 'Schedule', {
        content_name: 'Demo Signup',
        content_category: 'Account Type: ' + accountString,
      });
    }
  },
  fBPixelClick: function (e) {
    const linkAttr = e.target.getAttribute('data-free-trial-link');
    if (linkAttr && linkAttr.length && window.fbq) {
      window.fbq('track', 'Lead', {
        content_name: 'Free Trial Button Click',
        content_category: 'Button Location: ' + linkAttr,
      });
    }
  },
  pageview: function() {
    if ( 
      IS_DEV ||
      typeof window === 'undefined' || 
      !window.fbq || 
      ( this && !this.didInit )
    ) {
      return;
    }
    window.fbq('track', 'PageView');
  },
  init: function () {
    if (
      IS_DEV || 
      typeof window === 'undefined' || 
      (this && this.didInit)
    ) {
      // return console.log('do not load FB script, IS_DEV: ' + IS_DEV);
      return;
    }
    this.didInit = true;
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      // t.async = !0;
      t.setAttribute('defer',''); // changed to defer 011022
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    );
    window.fbq('init', '312292322773510');
    window.fbq('track', 'PageView');
  },
};

export default Facebook;
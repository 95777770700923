const DEFAULTS = {
  is_mobile: false,
  is_ios: false,
  is_android: false,
  screen_width: 0,
  screen_height: 0,
  screen_avail_width: 0,
  screen_avail_height: 0,
  orientation: 'landscape',
};

const DeviceData = {
  getDeviceDetails: function() {
    if (typeof window === 'undefined' || !window.navigator) {
      return DEFAULTS;
    }
    const is_ios = /iPhone|iPad|iPod/i.test(window.navigator.userAgent);
    const is_android = /Android/i.test(window.navigator.userAgent);
    const screenData = this.getScreenData();
    
    return {
      is_mobile: screenData.is_mobile,
      
      is_ios: is_ios,
      //is_ios_standalone: this.isIosStandalone(),
      
      is_android: is_android,
      //is_android_standalone: this.isAndroidStandalone(),
      
      viewport_width: screenData.viewport_width,
      viewport_height: screenData.viewport_height,

      //screen_width: screenData.screen_width,
      //screen_height: screenData.screen_height,
      
      //screen_avail_width: screenData.screen_avail_width,
      //screen_avail_height: screenData.screen_avail_height,
      
      //orientation: screenData.orientation,
    };
  }, // getDeviceDetails
  getScreenData: function() {
    const is_mobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
    
    // const ratio = window.devicePixelRatio || 1;
    // let screenW = window.screen.width * ratio;
    // let screenH = window.screen.height * ratio;
    // let screenAvailW = window.screen.availWidth * ratio;
    // let screenAvailH = window.screen.availHeight * ratio;
    
    //let screenW = window.screen.width;
    //let screenH = window.screen.height;
    //let screenAvailW = window.screen.availWidth;
    //let screenAvailH = window.screen.availHeight;
    //let orientation = screenW > screenH ? 'landscape' : 'portrait';
    
    let viewportWidth =  document.documentElement.clientWidth;
    let viewportHeight =  document.documentElement.clientHeight;

  /*
    if (is_mobile) {
      //const orientationPrimary = window.screen.msOrientation || window.screen.mozOrientation || (window.screen.orientation || {}).type;
      //const orientationBackup = window.matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape';
      //console.log('orientationPrimary: ' + orientationPrimary + ', orientationBackup: ' + orientationBackup);
      //orientation = orientationPrimary ? ( orientationPrimary.toLowerCase().indexOf('portrait') !== -1 ? 'portrait' : 'landscape' ) : orientationBackup;

      // const mobileScreenW = orientation === 'portrait' ?  // return WIDTH
        // (screenW > screenH ? screenH : screenW) : // in portrait, screen height is greater than width
        // (screenW > screenH ? screenW : screenH); // in landscape, screen width is greater than height
      // const mobileScreenH = orientation === 'portrait' ? // return HEIGHT
        // (screenW > screenH ? screenW : screenH) : // in portrait, screen height is greater than width
        // (screenW > screenH ? screenH : screenW); // in landscape, screen width is greater than height

      // const mobileScreenAvailW = orientation === 'portrait' ?  // return WIDTH
        // (screenAvailW > screenAvailH ? screenAvailH : screenAvailW) : // in portrait, screen height is greater than width
        // (screenAvailW > screenAvailH ? screenAvailW : screenAvailH); // in landscape, screen width is greater than height
      // const mobileScreenAvailH = orientation === 'portrait' ? // return HEIGHT
        // (screenAvailW > screenAvailH ? screenAvailW : screenAvailH) : // in portrait, screen height is greater than width
        // (screenAvailW > screenAvailH ? screenAvailH : screenAvailW); // in landscape, screen width is greater than height
        
      return {
        is_mobile: is_mobile,
        
        viewport_width: viewportWidth,
        viewport_height: viewportHeight,
        
        // screen_width: mobileScreenW || 0,
        // screen_height: mobileScreenH || 0,
        
        //screen_avail_width: mobileScreenAvailW || 0,
        //screen_avail_height: mobileScreenAvailH || 0,
        
        //orientation: orientation,
      };
    }
  */

    return {
      is_mobile: is_mobile,
      
      viewport_width: viewportWidth,
      viewport_height: viewportHeight,
      
      // screen_width: screenW || 0,
      // screen_height: screenH || 0,
      
      // screen_avail_width: screenAvailW || 0,
      // screen_avail_height: screenAvailH || 0,
      
      // orientation: orientation,
    };
  },
  /*
  isIosStandalone: function() {
    // see: https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/ConfiguringWebApplications/ConfiguringWebApplications.html
    return window.navigator && window.navigator.standalone === true
      ? true
      : false;
  }, // isIosStandalone
  isAndroidStandalone: function() {
    const url_is_standalone =
      window.location.href.indexOf('utm_source=homescreen') !== -1;
    const media_is_standalone = window.matchMedia('(display-mode: standalone)')
      .matches;
    return media_is_standalone || url_is_standalone;
    // see: https://developers.google.com/web/fundamentals/web-app-manifest/
    // and: https://developers.google.com/web/fundamentals/app-install-banners/#criteria
  }, // isAndroidStandalone
  hideMobileSafariUI: function() {
    if (
      /iPhone|iPad|iPod/i.test(window.navigator.userAgent) &&
      window.pageYOffset === 0 // user has not scrolled yet
    ) {
      if (document.readyState === 'complete') {
        // document already loaded
        window.scrollTo(0, 1);
      } else {
        // add load event listener to wait for window to load
        window.addEventListener('load', () => {
          window.scrollTo(0, 1);
        });
      }
    }
  } // hideMobileSafariUI
  */
};

export default DeviceData;

// import { useState, useEffect } from 'react';
import SA from 'superagent';
import { storageAvailable } from '../../helpers/hasLoggedIn';
// import { GdprConsentUi } from './gdpr-consent-ui';

const LS_KEY = 'pipeline_consent';
const okLocalStorage = storageAvailable('localStorage');

const consentDefault = {
  consent: false,
  country: '',
  region: '',
  eu: false,
};

const getLocation = async () => {
  try {
    // this URL will not work in local build/serve - no proxy to fwd to port:8080
    const response = await SA.get('/pp/gdpr').send();
    return(response);
  } catch (err) {
    return new Error(err);
  }
};

const checkIp = async () => {
  try {
    const result = await getLocation();
    if (!result || !result.body) {
      return consentDefault;
    }
    return {       
      country: result.body.country,
      region: result.body.region,
      eu: parseInt(result.body.eu, 10) > 0, // bool, "eu" is 0 or 1
    };
  } catch (err) {
    // if error, DO NOT show consent form
    return consentDefault;
  }
};

const storeConsentPref = (consent, country, region, eu) => {
  const curDate = new Date();
  const timestamp = curDate.getTime();
  const formatDate = ( parseInt(curDate.getMonth(), 10) + 1 ) + '_' + curDate.getDate() + '_' + curDate.getFullYear();        
  const consentObj = {
    consent: consent,
    country: country || '',
    region: region || '',
    eu: eu || '',
    date: formatDate,
    timestamp: timestamp,
  }
  window.localStorage.setItem(LS_KEY, JSON.stringify(consentObj));
};

const localConsentPref = () => {
  if (!okLocalStorage) {
    return null;
  }
  const consentObj = window.localStorage.getItem(LS_KEY);
  const parsed = consentObj ? JSON.parse(consentObj) : {};
  if (
    parsed === null || 
    typeof parsed !== 'object' || 
    !parsed.timestamp
  ) {
    return null;
  }
  return parsed;
};

export const checkConsent = async () => {
  const localStoredPref = localConsentPref();
  if ( !localStoredPref ) {
    const { country, region, eu } = await checkIp();
    if ( eu === true ) {
      storeConsentPref(false, country, region, eu);
      return false;
    } else {
      storeConsentPref(true, country, region, eu);
      return true;
    }
  } else {
    return localStoredPref.consent;
  }
};



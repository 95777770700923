import Debounce from './debounce';
import { loadDynamicScript } from './loadDynamicAssets';
import { hasLoggedIn } from './hasLoggedIn';

/*
  consent is in Beta testing:
  https://developers.google.com/gtagjs/devguide/consent
*/

const sentEventArr = [];
const GOOGLE_ANALYTICS_4_ID = 'G-0C7E7MQYTG';
const GOOGLE_ADWORDS_ID = 'AW-981765285';

const IS_DEV = process.env.GATSBY_ENVIRONMENT && process.env.GATSBY_ENVIRONMENT === 'dev';

const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + (days*24*60*60*1000));
  const expires = '; expires=' + date.toGMTString();
  document.cookie = name + '=' + value + expires + ';path=/;domain=paperlesspipeline.com';
}

const getParam = p => {
  const match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

const debounceSendGtag = Debounce((event, event_category, event_label, cb = null, event_params = null) => {
  if (IS_DEV) {
    event = `TESTING_${event}`;
    event_label = `TESTING_${event_label}`;
    event_category = `TESTING_${event_category}`;
    // return console.info('send google event testing: \n' + event + '\n' + event_label + '\n' + event_category);
    return;
  }
  if (sentEventArr.includes(event)) {
    return;
  }
  sentEventArr.push(event);
  if (event_params) {
    return window.gtag('event', event, event_params);
  }
  window.gtag('event', event, {
    'event_category': event_category && event_category.length ? event_category : 'no_category',
    'event_label': event_label && event_label.length ? event_label : 'no_label',
    'event_callback': () => {
      if (cb) {
        cb();
      }
    }
  });
}, 500);

const Google = {
  ts: null,
  didInit: false,
  storeGoogleGclidInCookie: function() {
    if (!this.didInit) {
      return;
    }
    // code taken from: https://support.google.com/google-ads/answer/7012522
    const gclid = getParam('gclid');
    if (gclid) {
      const gclsrc = getParam('gclsrc'); // gclsrc is added by Search Ads 360 - do not want
      if (!gclsrc || gclsrc.indexOf('aw') !== -1) {
        setCookie('gclid', gclid, 90);
      }
    }
  },
  setTimestamp: function(ts) {
    this.ts = ts;
  },
  sendGtagEvent: function(event, event_label, event_category, cb = null, event_params = null) {
    if (
      hasLoggedIn() ||
      typeof window === 'undefined' || /* need check for gatsby build */
      !window.gtag
    ) {
      return;
    }
    if (this && this.ts && this.ts.length) {
      const noSlashes = this.ts.replace(/\//g, '');
      event_category = `${event_category}_${noSlashes}`;
    }
    debounceSendGtag(event, event_category, event_label, cb, event_params);
  },
  pageview: function() {
    if (
      IS_DEV ||
      typeof window === 'undefined' ||
      !window.gtag ||
      ( this && !this.didInit )
    ) {
      return;
    }
    window.gtag('event', 'page_view', {
      // can customize sent params below
      // page_title: '<Page Title>',
      // page_location: '<Page Location>',
      // page_path: '<Page Path>',
      // send_to: '<GA_MEASUREMENT_ID>'
    });
  },
  init: function() {
    if (
      IS_DEV ||
      typeof window === 'undefined' ||
      ( this && this.didInit )
    ) {
      return;
    }
    this.didInit = true;
    loadDynamicScript('id_google', `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ADWORDS_ID}`, () => {
      window.dataLayer = window.dataLayer || [];
      var gtag = function(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', GOOGLE_ADWORDS_ID, { 'debug_mode': true });
      // gtag('config', GOOGLE_ANALYTICS_4_ID, { 'debug_mode': true });
      window.gtag = gtag;
      this.storeGoogleGclidInCookie(); // can only do this if GDPR consent is given...
    });
  }
};

export default Google;